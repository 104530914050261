<script setup>
import { ref, defineProps } from 'vue';


const props = defineProps({
  category: Object,
  index: Number
});

const collapsed = ref(false);
</script>

<template>
  <div class="category-list d-flex flex-row g-0">
    <div class="category-list__category col-10">
      <p class="category-list__category-name font-16 normal-font my-0"
        data-bs-toggle="collapse" 
        :data-bs-target="'#selected-themes-dropdown'+index">
        {{ `${props.index}. ${props.category.name}`}}
      </p>
    </div>
    <div 
      class="category-list__collapse col-2" 
      role="button">
      <img 
        class="category-list__collapse-icon icon-12"
        src="../../assets/img/icons/ic-dropdown-arrow.svg"
        :class="{'rotate-180': collapsed}" 
        @click="collapsed = !collapsed"
        data-bs-toggle="collapse" 
        :data-bs-target="'#selected-themes-dropdown'+index">
    </div>
  </div>
  <ul 
    class="category-list__themes collapse show pl-0" 
    :id="'selected-themes-dropdown'+index">
    <li 
      class="category-list__theme list-without-style" 
      v-for="(item, j) in props.category.themes" 
      :key="j">
      <div class="category-list__theme-name">
        <p class="category-list__theme-name-text font-14 thin-font no-margin-vertical">
          {{ `${props.index}.${j+1}. ${item.name}` }}
        </p>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


.category-list, .category-list__theme {
  margin-bottom: 4px;
  margin-top: 8px;
  margin-left: 8px;
  height: 28px !important;
}
</style>