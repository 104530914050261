import { getUser } from "./auth-header";

export default function () {
    const user = getUser();
    const error = user === null;
    
    return {
        error,
        user
    };
}