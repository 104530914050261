<script setup>
import CategoryList from '@/components/appeals/CategoryList.vue';
import Alert from '@/components/popup/Alert.vue';
import storedUser from '@/utils/storedUser';
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { getThemes, getAllThemes, setThemes } from '@/api/appeals';

const router = useRouter();
const popup = ref(null);
const { userError, user } = storedUser();

const screenLoading = ref(true);
const errorDescription = ref('Произошла ошибка. Попробуйте позже');
const error = computed(() => selectedThemesError.value || categoriesError.value );

const categories = ref([]);
const categoriesError = ref(false);

function selectCategory(i) {
  let category = categories.value[i];
  
  category.selected = !category.selected
  for (let i = 0; i < category.themes?.length; i++) {
    category.themes[i].selected = category.selected;
  }
  
  getSelected();
}

function selectTheme (i, j) {
  let category = categories.value[i];
  let theme = category.themes[j];
  
  theme.selected = !theme.selected;
  category.selected = category.themes.filter(th => th.selected === false).length === 0;
  
  getSelected();
}

const selectedThemes = ref(null);
const selectedThemesError = ref(false);

function getSelected () {
  let selected = [];
  for (let i = 0; i < categories.value?.length; i++) {
    for (let j = 0; j < categories.value[i].themes?.length; j++) {
      let theme = categories.value[i].themes[j];
      theme.num = j+1;

      if (!theme.selected)
        continue;

      let selectedCat = selected.filter(th => th.id === categories.value[i].id);

      if (selectedCat.length > 0) {
        selectedCat[0].themes.push(theme);
        continue;
      } 

      let prepCat = Object.assign({}, categories.value[i]);

      prepCat.num = i + 1;
      prepCat.themes = [];
      prepCat.themes.push(theme);

      selected.push(prepCat);
    }
  }
  selectedThemes.value = selected;
}

function mapTheme(theme) {
  let selected = selectedThemes.value.filter(th => th.id === theme.id);
  let counter = 0;

  if (selected.length > 0) {
    for (let i = 0; i < theme.themes?.length; i++) {
      theme.themes[i].selected = selected[0].themes?.filter(t => t.name === theme.themes[i].name)?.length > 0;
      if (theme.themes[i].selected)
        counter++;
    }
  }
  theme.selected = counter === theme.themes?.length;
  return theme;
}

const alertText = computed(() => {
  if (error.value)
    return errorDescription.value;

  if (!categories.value || categories.value[0].themes?.length === 0)
    return 'Нет доступных тем и категорий.';

  return 'Что-то пошло не так';
});

const categoriesVisible = computed(() => {
  return categories.value !== null;
});

const selectedVisible = computed(() => selectedThemes.value !== null);

const collapsed = ref([]);

async function fetchData () {
  await fetchThemes();
  if (!error.value && categories.value)
    collapsed.value = Array(categories.value?.length).fill(false);
}

async function fetchThemes () {
  screenLoading.value = true;

  await fetchSelectedThemes();
  await fetchAllThemes();
  
  screenLoading.value = false;
}

async function fetchSelectedThemes() {
  let result = await getThemes(user.orgId);
  if (result.error) {
    selectedThemesError.value = true;
    errorDescription.value = result.data;
  } else {
    selectedThemesError.value = false;
    selectedThemes.value = result.themes;
  }
}

async function fetchAllThemes () {
  let result = await getAllThemes();
  if (result.error) {
    categoriesError.value = true;
    errorDescription.value = result.data;
  } else {
    categoriesError.value = false;
    categories.value = result.themes.map(mapTheme);
  }
}

async function saveData() {
  if (selectedThemes.value?.length > 0) {
    await setSelectedThemes();
    await fetchThemes();
  } else {
    popup.value.show(
      'Изменения не сохранены. Должна быть выбрана хотя бы одна тема',
      true
    );
  }
}

async function setSelectedThemes () {
  let result = await setThemes(user.orgId, selectedThemes.value);
  if (result.error) {
    popup.value.show(
      'При сохранении произошла ошибка. ' + result.data,
      true
    );
  } else {
    popup.value.show(
      'Изменения успешно сохранены',
      false
    );
  }
}

onMounted(async () => {
  screenLoading.value = true;
  if (userError) {
    router.push('/login');
  } else {
    await fetchData();
  }

  screenLoading.value = false;
});
</script>

<template>
  <div class="categories container d-flex my-4 w-100 flex-fill">
    <div class="categories__content container-fluid d-flex flex-column h-100">
      <Alert ref="popup"/>
      <div class="categories__tab-content d-flex flex-column flex-fill bg-white border-radius-4 h-100 w-100 p-4">
        <div v-if="screenLoading" 
          class="d-flex justify-content-center align-items-center h-100 w-100">
          <div 
            class="spinner-border" 
            role="status"/>
        </div>
        <div v-else>
          <div v-if="error" 
            class="d-flex h-100 w-100 justify-content-center align-items-center">
            <img 
              class="alert-icon" 
              src="@/assets/img/icons/icon-no-appeals.svg" 
              alt="no-appeals">
            <p class="font-16 text-gray normal-font alert-text">
              {{ alertText }}
            </p>
          </div>
          <div v-else 
            class="container-fluid d-flex flex-column h-100 w-100">
            <div 
              class="categories-content d-flex flex-row">
              <!--Themes-->
              <div v-show="categoriesVisible" 
                class="col">
                <p class="font-14 thin-font text-gray">
                  Отредактируйте категории и темы для Вашей организации
                </p>
                <ul class="no-horizontal-padding">
                  <li class="list-without-style no-horizontal-padding left-border margin-top-18" 
                      v-for="(n, i) in categories?.length" 
                      :key="i">
                    <div class="collapse-item d-flex flex-row g-0 li-margin">
                      <div 
                        class="col-8"
                        data-bs-toggle="collapse" 
                        :data-bs-target="'#themes-dropdown'+i">
                        <p class="font-16 normal-font no-margin-vertical">
                          {{ `${i+1}. ${categories[i].name}`}}
                        </p>
                      </div>
                      <div 
                        class="col-2 d-flex flex-row-reverse align-items-center user-select-none"
                        @click="selectCategory(i)" 
                        role="button">
                        <p class="font-12 thin-font text-gray no-margin-vertical">
                          Выбрать все
                        </p>
                      </div>
                      <div class="col-1 d-flex flex-row-reverse no-margin-vertical">
                        <input 
                          class="form-check-input category-custom-checkbox"
                          @click="selectCategory(i)" 
                          role="button"
                          type="checkbox" 
                          :id="'category'+i" 
                          v-model="categories[i].selected">
                      </div>
                      <div class="col"/>
                      <div 
                        class="col" 
                        role="button">
                        <img 
                          class="icon-12" 
                          src="../../assets/img/icons/ic-dropdown-arrow.svg"
                          :class="{'rotate-180': collapsed[i]}" 
                          @click="collapsed[i] = !collapsed[i]"
                          data-bs-toggle="collapse" 
                          :data-bs-target="'#themes-dropdown'+i"
                          alt="dropdown-arrow">
                      </div>
                    </div>
                    <ul 
                      class="collapse show no-left-padding" 
                      :id="'themes-dropdown'+i">
                      <li 
                        class="list-without-style" 
                        v-for="(m, j) in categories[i].themes?.length" 
                        :key="j">
                        <div class="row height-28 collapse-item d-flex flex-row g-0 li-margin">
                          <div class="col-10">
                            <p class="font-14 thin-font no-margin-vertical">
                              {{ `${n}.${m}. ${categories[i].themes[j].name}` }}
                            </p>
                          </div>
                          <div class="col-1 d-flex flex-row-reverse no-horizontal-padding">
                            <input 
                              class="form-check-input custom-checkbox" 
                              type="checkbox"
                              @click="selectTheme(i, j)" 
                              role="button"
                              v-model="categories[i].themes[j].selected">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!--Selected themes-->
              <div v-show="selectedVisible" 
                class="col">
                <p class="font-14 thin-font text-gray">
                  Выбранные темы
                </p>
                <ul class="no-horizontal-padding">
                  <li 
                    class="list-without-style no-horizontal-padding left-border margin-top-18"
                    v-for="(item, index) in selectedThemes" 
                    :key="index">
                    <CategoryList 
                      :category="item" 
                      :index="index+1"/>
                  </li>
                </ul>
              </div>
            </div>
          <div 
            class="row mt-auto">
            <div class="col-sm-auto">
              <button 
                class="btn btn-primary save padding-12 background-blue border-radius-4 normal-font font-18" 
                id="save" 
                type="button" 
                @click="saveData">
                Сохранить
              </button>
            </div>
            <div class="col-sm-4 d-flex align-items-center">
              <label 
                class="form-label thin-font font-14 no-margin-vertical" 
                for="save">
                После сохранения данная информация станет сразу доступна пользователям мобильного приложения
              </label>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/pages/categories-plate.scss';
</style>